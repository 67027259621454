import React, { useEffect, useState } from "react";
import { Typography, Button, CardContent, Card, CardActions, CardMedia, Rating, Box, Chip, useMediaQuery, IconButton, Stack, Skeleton, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "next/link";
import Image from "next/image";
import router from "next/router";
import { ProductsResponse } from "@/interfaces/product/product-rec";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Config, ImgUrl, isProduction } from "@/config";
import Productservicenew from "../../components/responsive/productservicenew"
import { ja } from "date-fns/locale";
import { UseClearScrollPosition } from "@/hooks/UseClearScrollPosition";

interface Props {
  dataSet: {
    data: ProductsResponse;
  };
}

interface ProductItem {
  item_id: string;
  item_name: string;
  affiliation?: string | null;
  coupon?: string | null;
  discount: number;
  index: number;
  item_brand?: string | null;
  item_category?: string | null;
  item_category2?: string | null;
  item_category3?: string | null;
  item_category4?: string | null;
  item_category5?: string | null;
  item_list_id: string;
  item_list_name: string;
  item_variant?: string | null;
  location_id?: string | null;
  price: number;
  quantity: number;
}

export default function ProductService({ dataSet }: any) {

  const [isLoading, setIsLoading] = useState(true);
  const imgDefault = "/assets/images/logo/logomaboom.png"
  const [visibleProducts, setVisibleProducts] = useState([]);

  const handleRouter =()=>{
    // UseClearScrollPosition(["/product/product-service-new"])
    sessionStorage.removeItem("scrollPosition");
    sessionStorage.removeItem("offset"); // เคลียร์ค่าอื่นถ้ามี
    // router.push("/product/product-service-new");
    router.push("/product/service-all")
  }
  useEffect(() => {
    const updateVisibleProducts = () => {
      const width = window.innerWidth;
      let numberOfCards;

      if (width > 2160) {
        numberOfCards = 8;
      } else if (width > 1920) {
        numberOfCards = 6;
      } else if (width > 1439) {
        numberOfCards = 6;
      } else if (width > 1199) {
        numberOfCards = 5;
      } else if (width > 899) {
        numberOfCards = 4;
      } else {
        numberOfCards = 3;
      }
      setVisibleProducts(dataSet.slice(0, numberOfCards));
    };


    updateVisibleProducts();


    window.addEventListener('resize', updateVisibleProducts);


    return () => {
      window.removeEventListener('resize', updateVisibleProducts);
    };
  }, [dataSet]);




  if (dataSet === null) {
    return "Not found";
  }
  if (typeof dataSet === "object" && dataSet !== null && "message" in dataSet) {
    return dataSet.message;
  }

  function isEmpty(object: any) {
    for (const property in object) {
      return false;
    }
    return true;
  }

  // Format the price to the specified number of decimal places and return as a number
  const formatPrice = (price: number, digits: number = 2): number =>
    parseFloat(price.toFixed(digits));

  const itemList = {
    itemListId: "new_products",
    itemListName: dataSet[0]?.product_type === "Service" ? "บริการมาใหม่" : "สินค้ามาใหม่"
  };

  const handleProductClick = (
    item: any,
    itemList: { itemListId: string; itemListName: string }
  ): void => {
    if (!item) {
      if (!isProduction) {
        console.warn("No product data available.");
      }
      return;
    }

    // Map product data and format it
    const items: ProductItem[] = dataSet.map((item: any, idx: number) => {
      const {
        product_id,
        product_title,
        product_discounted_price,
        product_normal_price,
        affiliation = null,
        coupon = null,
        item_brand = null,
        item_category = null,
        item_category2 = null,
        item_category3 = null,
        item_category4 = null,
        item_category5 = null,
        item_variant = null,
        location_id = null,
      } = item;

      // Calculate price and discount
      const isDiscounted = product_discounted_price > 0;
      const price = formatPrice(
        isDiscounted ? product_discounted_price : product_normal_price
      );
      const discount = formatPrice(
        isDiscounted ? product_normal_price - product_discounted_price : 0
      );

      return {
        item_id: product_id,
        item_name: product_title,
        ...(affiliation && { affiliation }),
        ...(coupon && { coupon }),
        discount,
        index: idx,
        ...(item_brand && { item_brand }),
        ...(item_category && { item_category }),
        ...(item_category2 && { item_category2 }),
        ...(item_category3 && { item_category3 }),
        ...(item_category4 && { item_category4 }),
        ...(item_category5 && { item_category5 }),
        item_list_id: itemList.itemListId,
        item_list_name: itemList.itemListName,
        ...(item_variant && { item_variant }),
        ...(location_id && { location_id }),
        price,
        quantity: 1, // Default quantity to 1
      };
    });

    // Check if items array is valid
    if (!items || !Array.isArray(items)) {
      if (!isProduction) {
        console.error("Items array is not defined or not an array.");
      }
      return;
    }

    // Filter matching product
    const filteredData = items.filter(
      (product: any) => product.item_id === item.product_id
    );

    if (filteredData.length === 0) {
      if (!isProduction) {
        console.warn("No matching product found for removal.");
      }
      return;
    }

    // Update dataLayer for Google Analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce data
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_id: itemList.itemListId,
        item_list_name: itemList.itemListName,
        items: filteredData,
      },
    });

    if (!isProduction) {
      console.log("DataLayer updated:", {
        event: "select_item",
        ecommerce: {
          item_list_id: itemList.itemListId,
          item_list_name: itemList.itemListName,
          items: filteredData,
        },
      });
    }


    router.push({
      pathname: `/product/${item.product_slug}`,
      query: { store: item.store_id, id: item.product_id },
    });
  };


  return (
    <>
      <Grid container spacing={2}>
        {isEmpty(dataSet) ? (
          ""
        ) : (
          <>
            <Grid xs={12} md={12} sx={{ pt: "30px", pb: "1rem" }}>
              <Typography variant="h2" className="txt-bold"
                sx={{
                  fontSize: {
                    xs: '20px',
                    md: '25px'
                  },
                }}
              >
                {dataSet[0].product_type === "Service" ? (
                  "บริการมาใหม่"
                ) : (
                  "สินค้ามาใหม่"
                )}
              </Typography>
            </Grid>
          </>
        )}



      </Grid>

      <Grid 
        container 
        spacing={2} 
        sx={{ display: { xs: "none", md: 'flex', sm: "none" } }}
      >

        <Grid xs={12}>
          <Box
            sx={{
              display: 'grid',
              gap: '16px',
              gridTemplateColumns: 'repeat(4, 1fr)',
              width: '100%',
              // mt: '15px',
              // height: "100%",
              '@media (min-width: 1199px)': {
                gridTemplateColumns: 'repeat(5, 1fr)',
              },
              '@media (min-width: 1439px)': {
                gridTemplateColumns: 'repeat(6, 1fr)',
              },
              '@media (min-width: 1920px)': {
                gridTemplateColumns: 'repeat(6, 1fr)',
              },
              '@media (min-width: 2160px)': {
                gridTemplateColumns: 'repeat(8, 1fr)',
              },
            }}
          >
            {visibleProducts.map((item: any) => (

              <Link
                href={{
                  pathname: `/product/${item.product_slug}`,
                  query: { store: item.store_id, id: item.product_id },
                }}
                key={item.product_id}
                onClick={() => handleProductClick(item, itemList)}
                style={{ cursor: 'pointer' }}
              >

                <Card
                  className="product-card-best"
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: '14px',
                    transition: 'transform 0.3s, border 0.3s',
                    '&:hover': {
                      borderColor: '#f6cd64',
                      transform: 'translateY(-2px)',
                    },
                  }}
                >
                  <Box 
                    sx={{
                      textAlign: "center",
                      position: "relative",
                      width: "100%",
                      paddingBottom: "100%",
                    }}
                  >
                    <Image
                      src={item.product_thumbnail_image_url || imgDefault}
                      alt={item.product_thumbnail_image_url || imgDefault}
                      fill
                      style={{ objectFit: 'cover' }}
                    />
                  </Box>
                  <CardContent sx={{ height: "100%" }}>
                    <Typography
                      sx={{
                        mt: "10px",
                        mb: "10px",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 2,
                        whiteSpace: "normal",
                        color: "#717171",
                        fontSize: "16px",
                        lineHeight: "20px",
                      }}
                    >
                      {item.product_title}
                    </Typography>
                    <Stack direction="row">
                      <Typography
                        sx={{ fontSize: '18px', lineHeight: '20px' }}
                        className="txt-bold"
                        gutterBottom
                        color="secondary"
                      >
                        {item?.product_discounted_price == null || item.product_discounted_price === 0
                          ? `฿${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.product_normal_price)}`
                          : `฿${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.product_discounted_price)}`}
                      </Typography>
                      <Box 
                        sx={{
                          fontSize: "12px",
                          display: "inline-block",
                          textDecoration: "line-through",
                          pl: 1,
                          mt: 0.7,
                          fontWeight: "bold",
                          lineHeight: "14px",
                          color: "#AEAEAE"
                        }}
                      >
                        {item.product_discounted_price !== 0 && (
                          item.product_normal_price
                            ? `฿${item.product_normal_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                            : ``
                        )}  
                      </Box>
                    </Stack>
                    {item?.products?.product_percentage_discount > 0 ? (
                      <Chip
                        color="error"
                        size="small"
                        label={`ส่วนลด ${Math.trunc(item?.products?.product_percentage_discount)}%`}
                      />
                    ) : (
                      ""
                    )}
                  </CardContent>
                  <CardActions 
                    sx={{
                      justifyContent: "space-between",
                      display: "inline-block",
                      padding: '10px 15px',
                      textAlign: 'right'
                    }}
                  >
                    <Box display="inline-flex" sx={{ alignItems: 'center', float: "left" }}>
                      {item?.product_score > 0 && (
                        <Rating
                          name="read-only"
                          value={item?.product_score ?? 0}
                          precision={0.5}
                          readOnly
                          size="small"
                          sx={{ fontSize: 16 }}
                          max={1}
                        />
                      )}
                      {item?.product_score > 0 && (
                        <Typography
                          variant="caption"
                          sx={{ mr: 1, ml: 1, fontSize: 12 }}
                        >
                          {/* {parseFloat((item?.product_score ?? 0).toFixed(1))} */}
                          {item?.product_score}
                        </Typography>
                      )}
                    </Box>
                    {item?.product_sold > 0 && (
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontSize: 10, marginLeft: "0 !important" }}
                      >
                        ขายแล้ว {item?.product_sold} ชิ้น
                      </Typography>
                    )}
                  </CardActions>
                </Card>
              </Link>

            ))}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: { xs: "flex", md: 'none' } }}>
        <Productservicenew
          dataset={dataSet}

        />
      </Box>
      {/* {dataSet.length != 0 && (
        <Box sx={{ mt: 3 }} className="pagination"
        >
          <Button
            variant="outlined"
            disableRipple
            sx={{
              borderColor: "#DEE2E6", width: "300px", color: "#292929",
              '&:hover': {
                color: "#DB5A00",
                backgroundColor: "#ffffff"
              },
            }}
            onClick={handleRouter}
          ><Typography>ดูเพิ่มเติม</Typography></Button>
        </Box>
      )} */}
    </>
  );
}
