import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, Card, CardContent, Typography, Stack, Avatar } from '@mui/material'
import React from 'react'
import Link from "next/link";
import Image from "next/image";
import he from 'he';

type Props = {}

export default function ArticleRes({ dataSet }: any) {


    const decodeMultipleTimes = (str: any) => {
        let decoded = he.decode(str);
        while (decoded !== str) {
            str = decoded;
            decoded = he.decode(str);
        }
        return decoded;
    };

    const scrollContainer = React.useRef(null);

    const handleScroll = (direction: any) => {

        if (scrollContainer.current) {
            scrollContainer.current.scrollBy({
                left: direction == 'left' ? -100 : 100,
                behavior: 'smooth',

            });
        }
    };

    const aspectRatio = 3 / 2; 
    const width = 420;
    const height = width / aspectRatio;

  return (
    <>
          <Box
              sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  width: '100%',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                      display: 'none',
                  },
              }}
          >
              <IconButton
                  onClick={() => handleScroll('left')}
                  disableFocusRipple
                  disableRipple
                  sx={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      transform: 'translateY(-50%)',
                      fontSize: '12px',
                      zIndex: 1,
                      bgcolor: '#FFFFFF',
                      borderRadius: '4px',
                  }}
              >
                  <FontAwesomeIcon icon={faChevronLeft} />
              </IconButton>

              <Box
                  sx={{
                      display: 'flex',
                      overflowX: 'auto',
                      scrollSnapType: 'x mandatory',
                      p: "0 0 15px 0",
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none',
                      '&::-webkit-scrollbar': {
                          display: 'none',
                      },
                  }}
                  ref={scrollContainer}
              >
                  {dataSet?.map((data: any, idx: number) => (
                      <Link
                          href={`/community/article/${data.slug}`}
                          passHref key={idx}
                      >
                          <Card
                              className="product-card-best-moblie-Article"
                              sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  flex: '0 0 auto',
                                  width: '350px',
                                  height: '100%',
                                  mx: 1,
                                  scrollSnapAlign: 'start',
                                  overflow: 'hidden',
                                  transition: 'transform 0.3s, border 0.3s',
                                  '&:hover': {
                                      borderColor: '#f6cd64',
                                      transform: 'translateY(-2px)',
                                  },
                                  borderRadius: '12px',
                              }}
                          >
                              <Box position="relative" sx={{ width: '100%', height: 'auto', borderRadius: "12px" }}>
                                  <Image
                                      src={data.desktopCoverImageFullPath}
                                      alt={data.articleTitle}
                                      width={width}
                                      height={height}
                                      layout="responsive"
                                    //   objectFit="cover"
                                    //   priority
                                      style={{
                                          borderRadius: "12px",
                                          objectFit: 'cover',
                                      }}
                                      loading="lazy"
                                    placeholder="empty"
                                    blurDataURL={data.desktopCoverImageFullPath}
                                  />
                              </Box>

                              <CardContent sx={{ flex: '1 0 auto' }}>
                                  <Typography
                                      gutterBottom variant="h5" component="h3"
                                      sx={{
                                          display: '-webkit-box',
                                          WebkitBoxOrient: 'vertical',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          WebkitLineClamp: 2,
                                          whiteSpace: 'normal',
                                          color: "#000000",
                                          fontFamily: "NotoSans-SemiBold, NotoSansThai-SemiBold !important",
                                          fontSize: "18px",
                                          lineHeight: "22px",
                                          mb: "15px",
                                          fontWeight: "bold"
                                      }}
                                  >
                                      {data.articleTitle}
                                  </Typography>
                                  <Typography
                                      variant="body2"
                                      sx={{
                                          color: '#717171',
                                          fontSize: '16px',
                                          WebkitLineClamp: 3,
                                          display: '-webkit-box',
                                          WebkitBoxOrient: 'vertical',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'normal',
                                      }}
                                  >
                                      {decodeMultipleTimes(
                                          data.articleShortDesc.replace(/<\/?[^>]+(>|$)/g, '')
                                      )}
                                  </Typography>
                              </CardContent>

                              <CardContent
                                  sx={{
                                      p: 0,
                                      pl: '12px',
                                      pt: "5px"
                                  }}
                              >
                                  <Stack direction="row" spacing={2} alignItems="center">
                                      <Avatar
                                          alt=""
                                          sx={{ width: 20, height: 20 }}
                                          src={data.writer.WriterImageFullPath}
                                      />
                                      <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                          By {data.writer.writerName}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                          {data.createdAt}
                                      </Typography>
                                  </Stack>
                              </CardContent>
                          </Card>
                      </Link>
                  ))}
              </Box>

              <IconButton
                  onClick={() => handleScroll('right')}
                  disableFocusRipple
                  disableRipple
                  sx={{
                      position: 'absolute',
                      top: '50%',
                      right: "0px",
                      transform: 'translateY(-50%)',
                      fontSize: '12px',
                      zIndex: 1,
                      bgcolor: '#FFFFFF',
                      borderRadius: '4px',
                  }}
              >
                  <FontAwesomeIcon icon={faChevronRight} />
              </IconButton>
          </Box>
    </>
  )
}