import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Typography,
  CardContent,
  Card,
  CardActions,
  Rating,
  Box,
  Button,
  useMediaQuery,
  Stack,
  Chip,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Image from "next/image";
import duration from "dayjs/plugin/duration";
import { useRouter } from "next/router";
import { isProduction } from "@/config";
import { ICampaignProps, IProductProps } from "@/interfaces/campaign";

import dayjs from "dayjs";
import CountdownTime from "../campaign/countdownTime";
import ProductCampaignStart from "../../components/campaign/productCampaignStart";
import Link from "next/link";
dayjs.extend(duration);
interface IProductCampaignProps {
  data: ICampaignProps | undefined;
  dataBannerAds: any;
}
interface ProductItem {
  item_id: string;
  item_name: string;
  affiliation: string | null;
  coupon: string | null;
  discount: number;
  index: number;
  item_brand: string | null;
  item_category: string | null;
  item_category2: string | null;
  item_category3: string | null;
  item_category4: string | null;
  item_category5: string | null;
  item_list_id: string;
  item_list_name: string;
  item_variant: string | null;
  location_id: string | null;
  price: number;
  quantity: number;
}

const ProductCampaign = ({ data, dataBannerAds }: IProductCampaignProps) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [campaignRandom, setCampaignRandom] = useState<IProductProps[]>();
  const [length, setLength] = useState(0);
  const campaignEnd = data?.campaign_end as any;
  const [timeRemaining, setTimeRemaining] = useState<{
    hours: number;
    minutes: number;
    seconds: number;
  }>({ hours: 0, minutes: 0, seconds: 0 });
  const [endTime, setEndTime] = useState<Date | any>(
    dayjs(campaignEnd).toDate()
  );
  const [statusCampaignEnd, setStatusCampaignEnd] = useState(false);
  const product = data?.product as any;
  const imgDefault = "/assets/images/logo/logomaboom.png";
  const isLargerThan1920 = useMediaQuery("(min-width:1920px)");
  const isLargerThan2160 = useMediaQuery("(min-width:2160px)");
  const isLargerThan2500 = useMediaQuery("(min-width:2500px)");
  const dataImageCampaign = dataBannerAds?.find(
    (campaign: any) => campaign.ads_position === 1
  );
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [dataremaining, setRemaining] = useState<any>();
  const now = new Date();
  const generateRandomArray = useCallback(
    async (data: ICampaignProps, length: number) => {
      let list =
        data &&
        data?.product
          ?.map((x: any) => ({ x, r: Math.random() }))
          .sort((a: any, b: any) => a.r - b.r)
          .map((a: any) => a.x)
          .slice(0, length);

      setCampaignRandom(list);
    },
    []
  );

  useEffect(() => {
    const updateLength = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1925) {
        setLength(6);
      } else if (screenWidth <= 1920) {
        setLength(5);
      } else {
        setLength(4);
      }
    };

    updateLength();

    window.addEventListener("resize", updateLength);

    return () => {
      window.removeEventListener("resize", updateLength);
    };
  }, []);

  useEffect(() => {
    if (data) generateRandomArray(data, length);
  }, [data, length, generateRandomArray]);

  const calculateTimeRemaining = (
    endTime: Date
  ): { hours: any; minutes: any; seconds: any } => {
    const now = new Date().getTime();
    const distance = endTime.getTime() - now;
    if (distance <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor(distance / (1000 * 60 * 60))
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0");
    return { hours, minutes, seconds };
  };

  useEffect(() => {
    if (!endTime) return;

    if (endTime <= now) {
      setStatusCampaignEnd(false);
      setTimeRemaining({ hours: 0, minutes: 0, seconds: 0 });
      return;
    }
    const intervalId = setInterval(() => {
      const remaining = calculateTimeRemaining(endTime);
      setTimeRemaining(remaining);
      setRemaining(remaining);
      if (
        remaining.hours <= 0 &&
        remaining.minutes <= 0 &&
        remaining.seconds <= 0
      ) {
        clearInterval(intervalId);
        setStatusCampaignEnd(true);
        sessionStorage.setItem("hasReloaded", "true");
        router.reload();
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [endTime, router]);

  useEffect(() => {
    // console.log(product.length)
    const updateVisibleProducts = () => {
      const width = window.innerWidth;
      let numberOfCards;
      if (width >= 2160) {
        numberOfCards = 6;
      } else if (width >= 1920) {
        numberOfCards = 5;
      } else if (width >= 1309) {
        numberOfCards = 4;
      } else if (width < 1309) {
        numberOfCards = 4;
      } else {
        numberOfCards = product?.length;
      }
      setVisibleProducts(product?.slice(0, numberOfCards));
    };
    updateVisibleProducts();
    window.addEventListener("resize", updateVisibleProducts);
    return () => {
      window.removeEventListener("resize", updateVisibleProducts);
    };
  }, [product]);

  // Format the price to the specified number of decimal places and return as a number
  const formatPrice = (price: number, digits: number = 2): number =>
    parseFloat(price.toFixed(digits));

  const itemList = {
    itemListId: "campaign_products",
    itemListName: "สินค้า/บริการ แคมเปญ",
  };

  const handleProductClick = (
    item: any,
    itemList: { itemListId: string; itemListName: string }
  ): void => {
    if (!item) {
      if (!isProduction) {
        console.warn("No product data available.");
      }
      return;
    }

    // Map product data and format it
    const items: ProductItem[] = Array.isArray(data?.product)
      ? data?.product?.map((item: any, idx: number) => {
          const {
            product_id,
            product_title,
            product_discounted_price,
            product_normal_price,
            affiliation = null,
            coupon = null,
            item_brand = null,
            item_category = null,
            item_category2 = null,
            item_category3 = null,
            item_category4 = null,
            item_category5 = null,
            item_variant = null,
            location_id = null,
          } = item;

          // Calculate price and discount
          const isDiscounted = product_discounted_price > 0;
          const price = formatPrice(
            isDiscounted ? product_discounted_price : product_normal_price
          );
          const discount = formatPrice(
            isDiscounted ? product_normal_price - product_discounted_price : 0
          );

          return {
            item_id: product_id,
            item_name: product_title,
            ...(affiliation && { affiliation }),
            ...(coupon && { coupon }),
            discount,
            index: idx,
            ...(item_brand && { item_brand }),
            ...(item_category && { item_category }),
            ...(item_category2 && { item_category2 }),
            ...(item_category3 && { item_category3 }),
            ...(item_category4 && { item_category4 }),
            ...(item_category5 && { item_category5 }),
            item_list_id: itemList.itemListId,
            item_list_name: itemList.itemListName,
            ...(item_variant && { item_variant }),
            ...(location_id && { location_id }),
            price,
            quantity: 1, // Default quantity to 1
          };
        })
      : [];

    // Check if items array is valid
    if (!items || !Array.isArray(items)) {
      if (!isProduction) {
        console.error("Items array is not defined or not an array.");
      }
      return;
    }

    // Filter matching product
    const filteredData = items.filter(
      (product: any) => product.item_id === item.product_id
    );

    if (filteredData.length === 0) {
      if (!isProduction) {
        console.warn("No matching product found for removal.");
      }
      return;
    }

    // Update dataLayer for Google Analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_id: itemList.itemListId,
        item_list_name: itemList.itemListName,
        items: filteredData,
      },
    });

    // Log data in non-production environments
    if (!isProduction) {
      console.log("DataLayer updated:", {
        event: "select_item",
        ecommerce: {
          item_list_id: itemList.itemListId,
          item_list_name: itemList.itemListName,
          items: filteredData,
        },
      });
    }

    // Navigate to the product page
    router.push({
      pathname: `/product/${item.product_slug}`,
      query: { store: item.store_id, id: item.product_id },
    });
  };

  return (
    <>
      {endTime <= now ? (
        <></>
      ) : (
        <>
        
          <Grid container sx={{ mt: 3, mb: 1, alignItems: "center" }}>
            <Grid
              xs={12}
              sm={7}
              md={7}
              lg={7}
              sx={{ textAlign: { xs: "center", sm: "left" } }}
            >
              <Typography
                variant="h2" 
                sx={{
                  fontSize: { xs: "16px", md: "25px" },
                  fontFamily:
                    "NotoSans-SemiBold, NotoSansThai-SemiBold !important",
                  mt: { xs: "5px", md: "0px" },
                  mb: 1,
                }}
              >
                {data?.campaign_name}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={5}
              md={5}
              lg={5}
              sx={{ mb: 1, textAlign: { xs: "center" } }}
            >
              <CountdownTime campaign={data} alignment="right" />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ display: { xs: "none", md: "flex" }, height: "100%" }}
          >
            <Grid sm={0} md={0} lg={4} sx={{ mt: "0", display: { sm: "none", md: "inline-block", lg: "inline-block" } }}>
              {isLargerThan2500 ? (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    mt: 0,
                    borderRadius: "12px",
                    height: "100%"
                  }}
                >
                  {isLoading && (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                      }}
                    />
                  )}
                  <Image
                    src={dataImageCampaign?.image_extra_large || imgDefault}
                    alt={"banner-img"}
                    layout="responsive"
                    objectFit="contain"
                    height={500}
                    width={1}
                    // priority
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: "12px", 
                      objectFit: 'contain',
                    }}
                    loading="lazy"
                    placeholder="empty"
                    blurDataURL={dataImageCampaign?.image_extra_large || imgDefault}
                    onLoadingComplete={() => setIsLoading(false)}
                  />
                </Box>
              ) : isLargerThan2160 ? (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    mt: 0,
                    height: "100%",
                  }}
                >
                  {isLoading && (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                      }}
                    />
                  )}
                  <Image
                    src={dataImageCampaign?.image_extra_large || imgDefault}
                    alt={"banner-img"}
                    layout="responsive"
                    objectFit="contain"
                    height={500}
                    width={1}
                    // priority
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: "12px", 
                      objectFit: 'contain',
                    }}
                    loading="lazy"
                    placeholder="empty"
                    blurDataURL={dataImageCampaign?.image_extra_large || imgDefault}
                    onLoadingComplete={() => setIsLoading(false)}
                  />
                </Box>
              ) : isLargerThan1920 ? (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    mt: 0,
                    height: "auto",
                    borderRadius: "12px",
                  }}
                >
                  {isLoading && (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                      }}
                    />
                  )}
                  <Image
                    src={dataImageCampaign?.image_large || imgDefault}
                    alt={"banner-img"}
                    layout="responsive"
                    objectFit="contain"
                    height={500}
                    width={1}
                    // priority
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: "12px", 
                      objectFit: 'contain',
                    }}
                    loading="lazy"
                    placeholder="empty"
                    blurDataURL={dataImageCampaign?.image_large || imgDefault}
                    onLoadingComplete={() => setIsLoading(false)}
                  />
                </Box>
              ) : (
                <CardContent
                  sx={{
                    p: 0, paddingBottom: "0 !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "12px",
                  }}
                >
                  {isLoading && (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        position: "responsive",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                      }}
                    />
                  )}
                  <Image
                    src={dataImageCampaign?.image_medium || imgDefault}
                    alt=""
                    layout="intrinsic"
                    width={500}
                    height={0}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "12px",
                    }}
                    loading="lazy"
                    placeholder="empty"
                    blurDataURL={dataImageCampaign?.image_medium || imgDefault}
                    onLoadingComplete={() => setIsLoading(false)}
                  />
                </CardContent>
              )}
            </Grid>
            <Grid sm={12} md={12} lg={8}>
              <Box
                sx={{
                  display: "grid",
                  gap: "16px",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  width: "100%",
                  // mt: "15px",
                  "@media (min-width: 1440px)": {
                    gridTemplateColumns: "repeat(4, 1fr)",
                  },
                  "@media (min-width: 1920px)": {
                    gridTemplateColumns: "repeat(5, 1fr)",
                  },
                  "@media (min-width: 2160px)": {
                    gridTemplateColumns: "repeat(6, 1fr)",
                  },
                  height: "100%"
                }}
              >
                {visibleProducts.map((item: any) => (
                  <Link href={{
                    pathname: `/product/${item.product_slug}`,
                    query: { store: item.store_id, id: item.product_id }
                  }}
                    key={item.product_id}
                    onClick={() => handleProductClick(item, itemList)}
                    style={{ cursor: "pointer" }}
                  >
                    <Card
                      className="product-card-best"
                    
                      sx={{
                        width: '100%',
                        // minHeight: {
                        //   xs: "300px",
                        //   md: '320px',
                        // },
                        // maxHeight: '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderRadius: '14px',
                        transition: 'transform 0.3s, border 0.3s',
                        '&:hover': {
                          borderColor: '#f6cd64',
                          transform: 'translateY(-2px)',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "center",
                          position: "relative",
                          width: "100%",
                          paddingBottom: "100%",
                        }}
                      >
                        {isLoading && (
                          <Skeleton
                            variant="rectangular"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        )}
                        <Image
                          src={ item.product_image[0].image_full_url || imgDefault }
                          alt={item.product_title || imgDefault}
                          fill
                          // priority
                          style={{ objectFit: "cover" }}
                          loading="lazy"
                          placeholder="empty"
                          blurDataURL={item.product_image[0].image_full_url || imgDefault}
                          onLoadingComplete={() => setIsLoading(false)}
                        />
                      </Box>
                      {item.product_in_campaign === null ? (
                        <></>
                      ) : (
                        <img
                          src={data?.frame_product_full_url}
                          alt="Frame Product"
                          style={{
                            position: "absolute",
                            zIndex: 9,
                            top: 0,
                            left: "0px",
                            width: "100%",
                          }}
                        />
                      )}
                      <CardContent
                        sx={{
                          height: "100%",
                          // paddingBottom: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            mt: "10px",
                            mb: "10px",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 2,
                            whiteSpace: "normal",
                            color: "#717171",
                            fontSize: "16px",
                            lineHeight: "20px",
                          }}
                        >
                          {item.product_title}
                        </Typography>
                        <Stack direction={"row"}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              lineHeight: "20px",
                              mr: 1
                            }}
                            className="txt-bold"
                            gutterBottom
                            color="secondary"
                          >
                            ฿{""}{item?.product_discount_price}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              textDecoration: "line-through",
                              // pl: 1,
                              mt: 0.5,
                              fontWeight: "bold",
                              lineHeight: "14px",
                              color: "#AEAEAE",
                              whiteSpace: "nowrap",
                              display: "inline-block",
                            }}
                          >
                            {item.product_discounted_price === 0
                              ? ''
                              : `฿${item.product_normal_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                          </Typography>
                        </Stack>
                      </CardContent>
                      <CardActions
                        sx={{
                          justifyContent: "space-between",
                          display: "inline-block",
                          padding: '10px 15px',
                          textAlign: 'right'
                        }}
                      >
                        <Box display="inline-flex" sx={{ alignItems: "center", float: "left" }}>
                          {item?.product_score > 0 && (
                            <Rating
                              name="read-only"
                              value={item?.product_score ?? 0}
                              precision={0.5}
                              readOnly
                              size="small"
                              sx={{ fontSize: 16 }}
                              max={1}
                            />
                          )}
                          {item?.product_score > 0 && (
                            <Typography
                              variant="caption"
                              sx={{ mr: 1, ml: 1, fontSize: 12 }}
                            >
                              {/* {Number(item?.product_score ?? 0).toFixed(1)} */}
                              {item?.product_score}
                            </Typography>
                          )}
                        </Box>
                        {item?.product_sold > 0 && (
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{ fontSize: 10, marginLeft: "0 !important" }}
                          >
                            ขายแล้ว {item?.product_sold} ชิ้น
                          </Typography>
                        )}
                      </CardActions>
                    </Card>
                  </Link>
                ))}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <ProductCampaignStart data={data} />
          </Box>
        </>
      )}

      {data === null ? (
        <></>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              disableRipple
              sx={{
                borderColor: "#DEE2E6",
                width: "300px",
                color: "#292929",
                "&:hover": {
                  color: "#DB5A00",
                  backgroundColor: "#ffffff",
                },
              }}
              onClick={() => {
                router.push("/campaign/");
              }}
            >
              <Typography>ดูเพิ่มเติม</Typography>
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default ProductCampaign;
