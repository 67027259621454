import Producttype from "@/components/cards/product-type";
import Bannerhome from "@/components/cards/banner-home";
import ProductCampaign from "@/components/cards/product-campaign";
import ProductService from "@/components/cards/product-service";
import Productrecommend from "@/components/cards/product-recommend";
import Article from "@/components/cards/article";
import { BreadcrumbJsonLd, DefaultSeo } from "next-seo";


import * as ApiProduct from "../api/product";
import * as ApiArticle from "../api/article";
import { BannerHome } from "../api/petsploy-center";
import ProductHomepage from "../components/cards/product-homepage";
import * as Campaign from "../api/campaign";
import { GetServerSideProps } from "next";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import SkeletonHome from "@/components/skeleton/home";
import BannerCampaign from "@/components/cards/banner-campaign-top";
import CampaignPopupDialogs from "@/components/dialog/campaigmPopUp";
import { ICampaignProps } from "@/interfaces/campaign";
import Cookies from "universal-cookie";
import { ProducHomeBestSell, ProducHomeRecommend } from "../api/product";
import Activity from "@/components/cards/activity";
import TabCategoryProduct from "@/components/searchAll/TabCategoryProduct";
import { GetBannerAds } from "@/api/Banner/banner";
import Pathner from "./partner";
import { Config, isProduction } from "@/config";
import ComingSoonCampaign from "../components/campaign/campaigncomingsoon"
import { GetCampaignComingSoon } from "@/api/campaign/campaign";
import Script from "next/script";
import SkeletonCard from "@/components/skeleton/card";
import { seoConfig } from "@/config/seo.config";
import { MetaTag } from "next-seo/lib/types";
import { restoreScrollPosition } from "@/utils/resstoreScrollPostion";
import { Box, Button, Typography } from "@mui/material";
import { useRouter } from "next/router";



export default function Home({
  dataProductHome, dataRecommend, dataArticle, dataProductType,
  dataBannerHome, dataCampaignHalloween, getDataCampaignHalloween,
  dataCurrentCampaign, dataActivity, dataBannerAds, dataProductNew, datacampaignComingSoon, dataServiceNew }: any) {
  
 
  const [isLoading, setIsLoading] = useState(true);
  const [isBannerHomeLoading, setIsBannerHomeLoading] = useState(true);
  const [isProductServiceLoading, setIsProductServiceLoading] = useState(true);
  const [isArticleLoading, setIsArticleLoading] = useState(true);
  const [isProductHomepageLoading, setIsProductHomepageLoading] = useState(true);
  const [isProductRecommendLoading, setIsProductRecommendLoading] = useState(true);
  const [isActivityLoading, setIsActivityLoading] = useState(true);
  const currentUrl = typeof window !== "undefined" ? window.location.href : Config.maaBoom;
  const router = useRouter();
  const [catId, setCatId] = useState<any>()

  // useLayoutEffect(() => {
  //   restoreScrollPosition("ScrollHomepage"); // เรียกใช้เมื่อ Component โหลดเสร็จ
  //      sessionStorage.removeItem("scrollPosition");
  //      sessionStorage.removeItem("offset");
  //      sessionStorage.removeItem("limit");
  //   const handleScroll = () => {
  //     sessionStorage.setItem("ScrollHomepage", window.scrollY.toString());
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const [isCurrentCampaignLoading, setIsCurrentCampaignLoading] = useState(true);
  

  useEffect(() => {
    // Determine if all data is loaded
    const allDataLoaded =
      dataProductHome &&
      dataRecommend &&
      dataActivity &&
      dataArticle &&
      dataBannerAds &&
      dataProductType &&
      dataBannerHome &&
      dataProductNew;

    if (allDataLoaded) {
      setIsLoading(false);
    }
  }, [
    dataProductHome,
    dataRecommend,
    dataActivity,
    dataArticle,
    dataBannerAds,
    dataProductType,
    dataBannerHome,
    dataProductNew,
  ]);

  useEffect(() => {
    if (dataBannerHome) {
      setIsBannerHomeLoading(false);
    }
  }, [dataBannerHome]);

  useEffect(() => {
    if (dataProductNew) {
      setIsProductServiceLoading(false);
    }
  }, [dataProductNew]);

  useEffect(() => {
    if (dataArticle) {
      setIsArticleLoading(false);
    }
  }, [dataArticle]);

  useEffect(() => {
    if (dataProductHome) {
      setIsProductHomepageLoading(false);
    }
  }, [dataProductHome]);

  useEffect(() => {
    if (dataRecommend) {
      setIsProductRecommendLoading(false);
    }
  }, [dataRecommend]);

  useEffect(() => {
    if (dataCurrentCampaign) {
      setIsCurrentCampaignLoading(false);
    }
  }, [dataCurrentCampaign])

  useEffect(() => {
    if (dataActivity) {
      setIsActivityLoading(false);
    }
  }, [dataActivity]);

  const handleRouter =(type: string)=>{
    // UseClearScrollPosition(["/product/product-service-new"])
    // sessionStorage.removeItem("scrollPosition");
    // sessionStorage.removeItem("offset"); // เคลียร์ค่าอื่นถ้ามี
    // router.push("/product/product-service-new");
    // router.push("/product/service-all")
    if (type === "Service") {
      // router.push("/product/service-all");
      window.location.href = "/product/service-all"
    } else {
      window.location.href = "/product/product-all"
      // router.push("/product/product-all");
    }
  }

  return (
    <>

      {isLoading ? (
        <SkeletonHome />
      ) : (
        <>
          <DefaultSeo
              title={seoConfig.title}
              titleTemplate={seoConfig.titleTemplate}
              defaultTitle={seoConfig.defaultTitle}
              description={seoConfig.description}
              canonical={currentUrl}
              openGraph={{
                ...seoConfig.openGraph,
                url: currentUrl,
                title: seoConfig.title,
              }}
              norobots={true}
              additionalMetaTags={[
                ...seoConfig.additionalMetaTags,
                isProduction ? seoConfig.robots.isProduction : seoConfig.robots.isNotProduction,
              ] as MetaTag[]}
              twitter={seoConfig.twitter}
              facebook={seoConfig.facebook}
            />

          {/* first popup */}
          <CampaignPopupDialogs data={dataCurrentCampaign}/>

            <BreadcrumbJsonLd
              itemListElements={[
                {
                  position: 1,
                  name: 'หน้าแรก',
                  item: currentUrl,
                },

              ]}
            />

            {/* <Script id="gtm-script-tag" strategy="afterInteractive">
              {`window.dataLayer = window.dataLayer || [];`}
            </Script> */}

            <TabCategoryProduct setCatId={setCatId}/>

            {dataCurrentCampaign && <BannerCampaign data={dataCurrentCampaign} />}

            {!isBannerHomeLoading ? (
              <Bannerhome Bannerhome={dataBannerHome} />
            ) : (
              <SkeletonCard />
            )}

            <Producttype dataSet={dataProductType}></Producttype>

            {getDataCampaignHalloween?.data?.length > 0 ? (
              <ProductCampaign
                data={dataCampaignHalloween.products}
                dataBannerAds={dataBannerAds}
              />
            ) : (
              ""
            )}

            <ComingSoonCampaign datacampaignComingSoon={datacampaignComingSoon} dataBannerAds={dataBannerAds} />

            {/* แคมเปญ */}
            {!isCurrentCampaignLoading ? (
              <ProductCampaign data={dataCurrentCampaign} dataBannerAds={dataBannerAds}/>
            ) : (
              // <SkeletonCard/>
              ""
            )}
            

            {/* บริการมาใหม่ */}
            {!isProductServiceLoading ? (
              <>
                <ProductService dataSet={dataServiceNew}/>
                {dataServiceNew?.length != 0 && (
                  <Box sx={{ mt: 3 }} className="pagination">
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        borderColor: "#DEE2E6", width: "300px", color: "#292929",
                        '&:hover': {
                          color: "#DB5A00",
                          backgroundColor: "#ffffff"
                        },
                      }}
                      onClick={() => handleRouter("Service")}
                    >
                      <Typography>ดูเพิ่มเติม</Typography>
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <SkeletonCard/>
            )}

            {/* สินค้ามาใหม่ */}
            {!isProductServiceLoading ? (
              <>
                <ProductService dataSet={dataProductNew}/>
                {dataProductNew?.length != 0 && (
                  <Box sx={{ mt: 3 }} className="pagination">
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        borderColor: "#DEE2E6", width: "300px", color: "#292929",
                        '&:hover': {
                          color: "#DB5A00",
                          backgroundColor: "#ffffff"
                        },
                      }}
                      onClick={() => handleRouter("Product")}
                    >
                      <Typography>ดูเพิ่มเติม</Typography>
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <SkeletonCard/>
            )}
            
            {/* บทความ */}
            {!isArticleLoading ? (
              <Article dataSet={dataArticle}></Article>
            ) : (
              <SkeletonCard />
            )}

            {/* สินค้า/บริการ แนะนำประจำวัน */}
            {!isProductHomepageLoading ? (
              <ProductHomepage
                dataSet={dataProductHome}
                pagination={true}
                dataBannerAds={dataBannerAds}
              ></ProductHomepage>
            ) : (
              <SkeletonCard />
            )}
            {/* สินค้า/บริการ ที่คุณอาจจะชอบ */}
            {!isProductRecommendLoading ? (
              <>
                <Productrecommend dataBannerAds={dataBannerAds} />
              </>
             
            ) : (
              <SkeletonCard />
            )}

            {!isActivityLoading ? (
              <Activity dataActivity={dataActivity} />
            ) : (
              <SkeletonCard />
            )}

            <Pathner />

        </>
      )}
    
    </>
  );
}


export const getServerSideProps: GetServerSideProps = async (context) => {
  const limitBestSell = "4";
  const limitHomepage = "16" as any;
  const limitMayLike = "16";
  const producType = "Product";
  const authToken: any = context.req.cookies.token;

  try {
    const [
      resProductGroup,
      resProductType,
      resProductBestsellInfo,
      resProductHome,
      resProductRec,
      getCampaignHalloween,
      resArticle,
      resActivity,
      currentCampaign,
      resBannerHome,
      resBannerAds,
      productNew, // reflector
      serviceNew, // reflector
      responseCampaignComingSoon,
    ] = await Promise.all([
      ApiProduct.ProductGroup(),
      ApiProduct.ProductType(authToken),
      ApiProduct.ProducHomeBestSell(limitBestSell, producType),
      ApiProduct.ProducHomePage(limitHomepage),
      ApiProduct.ProducHomeRecommend(producType, authToken, limitMayLike),
      Campaign.GetCampaign(authToken),
      ApiArticle.Article(authToken),
      ApiArticle.ActivityDetail(authToken),
      Campaign.getCurrentCampaign(),
      BannerHome(),
      GetBannerAds(authToken),
      ApiProduct.GetProducts(0, 12, "Product"), // reflector
      ApiProduct.GetProducts(0, 12, "Service"), // reflector
      GetCampaignComingSoon(),
    ]);

    let resProductCampaignHalloween = null;
    if (getCampaignHalloween?.data?.data?.length > 0) {
      resProductCampaignHalloween = await Campaign.GetCampaignProduct(
        authToken,
        getCampaignHalloween.data.data[0].campaign_id,
        4,
        "random"
      );
    }

    if (
      resProductRec?.status === false ||
      resProductBestsellInfo?.status === false ||
      resArticle?.status === false ||
      resProductType?.status === false ||
      resProductGroup?.status === false ||
      resActivity?.status === false
    ) {
      return {
        redirect: {
          destination: "/404",
          permanent: false,
        },
      };
    }

    const dataProductGroup = resProductGroup?.data || null;
    const dataProductType = resProductType?.data || null;
    const dataBestSell = resProductBestsellInfo?.data || null;
    const dataProductHome = resProductHome?.data || null;
    const dataRecommend = resProductRec?.data || null;
    const dataArticle = resArticle || null;
    const dataBannerHome = resBannerHome?.data || null;
    const dataActivity = resActivity || null;
    const dataBannerAds = resBannerAds?.data || null;

    let dataCampaignHalloween = null;
    if (resProductCampaignHalloween !== null) {
      dataCampaignHalloween = resProductCampaignHalloween.data.data || null;
    }

    const getDataCampaignHalloween = getCampaignHalloween?.data || null;
    // const dataCampaign = getCurrentCampaign?.data || null;

    const dataCurrentCampaign = currentCampaign.success === true ? currentCampaign.data : []; // reflector
    const dataProductNew = productNew.success === true ? productNew.data : []; // reflector
    const dataServiceNew = serviceNew.success === true ? serviceNew.data : []; // reflector

    const datacampaignComingSoon =
      responseCampaignComingSoon.success === true
        ? responseCampaignComingSoon.data
        : [];

    return {
      props: {
        dataProductGroup,
        dataProductType,
        dataBestSell,
        dataProductNew, // reflector
        dataProductHome,

        dataServiceNew, // reflector
        dataRecommend, 

        dataArticle,
        dataBannerHome,
        dataCampaignHalloween,
        getDataCampaignHalloween,
        dataCurrentCampaign,
        dataActivity,
        dataBannerAds,
        datacampaignComingSoon,
      },
    };
  } catch (error) {
    if (!isProduction) {
      console.error("Error in getServerSideProps:", error);
    }
    return {
      redirect: {
        destination: "/404",
        permanent: false,
      },
    };
  }
};