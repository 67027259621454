import { Config, ConfigMember } from "@/config";
import { ICreatePinConfirm, IEditPinConfirm, IEditPinOtp, IGetMember, IPinVerify, IRegisterMember, ISignupSmsOtpVerify, ISmsOtpRequest,IValidateOneTimeCode } from "@/interfaces/account";
import { CampaignProps } from "@/interfaces/campaign";
import axios from "axios";

export const SmsOtpRequest = async (dataForm: ISmsOtpRequest) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/utility/sms-otp-request`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data: dataForm
    });
    return data.data as ISmsOtpRequest;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


export const SignupSmsOtpVerify = async (dataForm: ISignupSmsOtpVerify) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      // url: "http://api.maaboom.com/api/v1/member/signup-sms-otp-verify",
      url: ConfigMember.apiEndpoint + `v1/member/signup-sms-otp-verify`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data: dataForm
    });
    return data.data as ISignupSmsOtpVerify;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


export const SignInSmsOtpVerify = async (dataForm: ISignupSmsOtpVerify) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      // url: "http://api.maaboom.com/api/v1/auth/login-sms-otp-verify",
      url: ConfigMember.apiEndpoint + `v1/auth/login-sms-otp-verify`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data: dataForm
    });
    // console.log(data);
    
    
    return data.data as any;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


export const  LoginPinVerify = async (dataForm: IPinVerify,token:string ) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/auth/login-pin-verify`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
          "x-access-token": token,
        "Content-Type": "application/json",
      },
      data: dataForm
    });
    return data.data as any;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};

//เอาไว้เช็คOTP
export const EditPinOtp = async (dataForm: IEditPinOtp) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/auth/edit-pin-otp-verify`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data: dataForm
    });
    return data.data as any;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};

//เอาไว้สร้างและedit pin
export const PostEditPinConfirm = async (dataForm: IEditPinConfirm) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/auth/edit-pin-confirm`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
        data: dataForm
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


export const PostCreatePinConfirm = async (dataForm: ICreatePinConfirm) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/auth/edit-pin-confirm`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
        data: dataForm
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


//เอาไว้เช็คว่าจะสร้างหรือแก้ไขพิน ส่งmember_idไปด้วย
export const GetCheckPinMember = async (token: string , memberId:number) => {

  
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/utility/get-check-pin-member/${memberId}`,
      headers: {
        "accept-language": "th",
        "x-access-token": token,
        Authorization: "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data:memberId,
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


export const PostOtpPin = async (dataForm:IEditPinOtp) => {
  // console.log(memberId);
  
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/auth/edit-pin-otp-verify`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data:dataForm
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};




export const RegisterMember = async (dataForm: IRegisterMember) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      // url: "http://api.maaboom.com/api/v1/member/register-member",
      url: ConfigMember.apiEndpoint + `v1/member/register-member`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data: dataForm
    });
    return data.data as any;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};
export const ValidateOneTimeCode = async (dataForm: IValidateOneTimeCode) => {
  try {
    let verifyData = {
      code: dataForm.code,
      provider: dataForm.provider
    }
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/auth/validate-one-time-code`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data: verifyData
    });
    return data.data as any;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};

export const examineFormUsed = async (fieldName: string, fieldValue: string) => {
  try {
    let formData = {
      fieldName: fieldName,
      fieldValue: fieldValue
    }
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/member/examine-form-used`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data: formData
    });
    return data.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
}

export const validateSubmitFormRegister = async (data: IRegisterMember) => {
  try {
    const response = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/member/validate-submit-form-register`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data: data
    });
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
}

export const verifySecureTempToken = async (tempToken: string) => {
  try {
    const response = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/auth/verify-secure-temp-token`,
      headers: {
        "Authorization": "Basic " + ConfigMember.encoded,
        "Content-Type": "application/json",
      },
      data: {
        secureTempToken: tempToken
      }
    });
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
}